@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "poppins";
}

/* Home */
.backgroundimage {
  background-image: url('../img/homeimage.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
  height: 95vh;
  background-color: #000;
  opacity: 1;
}

.homecolour {
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
}

.servicesbackground {
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
}

.customerbackground {
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
}

.customerscontent {
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.section-head {
  margin-bottom: 60px;
}

.hometext {
 
    /* margin-top: 200px !important; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:6%;
  
}
.section-head h4 {
  position: relative;
  padding: 0;
  color: #ffffff;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.section-head h4:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #a31703;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}



.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #ffffff
}

p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.section-head p,
p.awesome_line {
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}

.mobileapp {
  color: white;
}

.extra-text::before {
  content: '';
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.extra-text span {
  font-weight: 700;
  color: #f91942;
}

.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
  height: 350px;
}

.item:hover {
  background: #a31703;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover h6,
.item:hover p {
  color: #fff;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #a31703;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 60px;
}

.item .feature_box_col_one {
  /* background:rgba(247, 198, 5, 0.20);
    color:#f91942 */
}

.item .feature_box_col_two {
  /* background:rgba(255, 77, 28, 0.15); */
  /* color:#f91942 */
}

.item .feature_box_col_three {
  /* background:rgba(0, 147, 38, 0.15); */
  /* color:#f91942 */
}

.item .feature_box_col_four {
  background: rgba(0, 108, 255, 0.15);
  color: #f91942
}

.item .feature_box_col_five {
  background: rgba(146, 39, 255, 0.15);
  color: #f91942
}

.item .feature_box_col_six {
  background: rgba(23, 39, 246, 0.15);
  color: #f91942
}

.item p {
  font-size: 16px;
  line-height: 26px;
}

.item h6 {
  margin-bottom: 20px;
  color: #2f2f2f;
}

.mission p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}

.mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f91942;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}

.mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}

.skills {
  padding-top: 0px;
}

.skills .prog-item {
  margin-bottom: 25px;
}

.skills .prog-item:last-child {
  margin-bottom: 0;
}

.skills .prog-item p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}

.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}

.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #f91942;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}


.bg-gray {
  /* background-image: radial-gradient( circle 919px at 1.7% 6.1%,  rgba(41,58,76,1) 0%, rgba(40,171,226,1) 100.2% ); */
  background-image: linear-gradient(114.9deg, rgba(34, 34, 34, 1) 8.3%, rgba(0, 40, 60, 1) 41.6%, rgba(0, 40, 60.1) 93.4%);

}

.bgbackground {
  background-image: linear-gradient(114.9deg, rgba(34, 34, 34, 1) 8.3%, rgba(0, 40, 60, 1) 41.6%, rgba(0, 40, 60.1) 93.4%);
}



/* Contact  */

.contactbanner {
  background-image: url('../img/contact.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;
}


/* Responsive css */
@media only screen and (max-width: 768px) {
  .box-wrapper {
    width: 100%;
  }

  .info-wrap,
  .form-wrap {
    width: 100%;
    height: inherit;
    float: none;
  }

  .info-wrap {
    border-radius: 30px 30px 0px 0px;
  }

  .form-wrap {
    border-radius: 0px 0px 30px 30px;
  }

  .form-group {
    width: 100%;
    float: none;
    margin: 25px 0px;
  }

  .form-fields .form-group:last-child,
  .submit-button {
    width: 100%;
  }

  .submit-button {
    margin: 10px 0px;
  }

}



/* About */

.bannercontent {
  font-size: 56px;
  font-weight: 600;
  color: white;
  text-align: center;
  position: relative;
  top: 45%;
}

.innerbanner {
  background-image: url('../img/innerbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;
}

.businesscontent {
  font-size: 40px;
  font-weight: 700;
}


.testimonialcontent {
  font-size: 54px !important;
}

.displaycontents {
  text-align: center;
  font-size: 60px;
  color: white;
  font-weight: 700;
}

.banner-line-hight {
  line-height: 1.5;
  font-size: 19px;
  color: white;
  font-weight: 500;

}

.banner-container {
  /* position: relative; */
  height: 100vh;
}

.horizontal-line {
  border-bottom: 4px solid rgb(255, 79, 20);
  width: 38%;
  margin-left: 15px;
}

.banner-content {
  width: 45%;
  position: absolute;
  top: 42%;
  left: 45%;
  transform: translate(-100%, -55%);
}


#counter {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(125deg, rgba(134, 36, 171, 1) 13%, rgba(231, 2, 12, 1) 100%);
  padding: 40px 0px;
  width: 100%;
}

#counter .item {
  background: #fff;
  width: 200px;
  padding: 35px 40px;
  margin: 10px;
  text-align: center;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 38px -8px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 38px -8px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 0px 38px -8px #000000;
  transition: all 1s;
  border-radius: 50%;

}

#counter .item .count {
  color: #ff6600;
  margin-bottom: 5px;
  font-size: 40px;
}

#counter .item h3 {
  color: #4f4e28;
  text-transform: capitalize;
}

.countertext {
  font-size: 20px;
  font-weight: 700;
  color: #212529;
}

.quotes {
  font-size: 19px;
  width: 370px;
  color: #212529;
}



.contact {
  background-color: rgb(3, 3, 51);
}

.contactcontent {
  text-align: left;
  font-size: 28px;
  color: #ffffff;
  font-weight: 500;
}

.contactpage {
  background-color: rgb(3, 3, 51);
}

.autocontent {
  font-size: 20px;
  color: #fff;
  text-align: justify;
}

.buttoncontent {
  background-color: #f76403;
  border-radius: 12px;
  box-sizing: inherit;
  color: white;
  width: 200px;
  height: 70px;
  font-size: 1.5rem;
  font-weight: 700;
  box-shadow: #fff;
}

.bmrcontent {
  font-size: 20px;
  color: white;
  font-weight: 900;
}

.buttoncontent:hover span {
  padding-right: 25px;
}

.buttoncontent:hover span:after {
  opacity: 1;
  right: 0;
}

.counterpage {
  background: linear-gradient(125deg, rgba(134, 36, 171, 1) 13%, rgba(231, 2, 12, 1) 100%);
}

.testimonials {
  background-color: #cccccc;

}

.testimonialtext {
  color: #212529;
  font-size: 20px;
}

.contentsectionbanner {
  background-color: #04003a;
}
/*END SOCIAL PROFILE CSS*/
.expertiseCont {
  color: #fff;
}

@media screen and (min-width: 1440px) {
  .banner-line-hight {
    line-height: 1.5;
    font-size: 25px !important;
    font-weight: 400;
  }

  .business {
    font-size: 31px;
    color: #a31703;
    font-weight: 800;

  }
}

@media screen and (min-width: 1024px) {

  .softwarecontents {

    font-size: 18px !important;

  }

  .midrangecontent {
    font-size: 23px;
    font-weight: 600;

  }

  .custom {
    width: 79px;
  }

  .socializetext {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 25px !important;

  }

  .mobileapp {
    color: rgb(255, 255, 255) !important;
    font-size: px !important;
    font-weight: 700 !important;
  }

  .expertiseCont {
    color: #fff;
    font-size: 15px;
  }



  .servicecontents {

    font-size: 20px !important;
  }

  .getintouch {

    font-size: 40px !important;
  }

  .getintouch {

    font-size: 40px;
  }

  .businesscontent {
    font-size: 40px !important;
    font-weight: 600;
  }

  /* .aboutcontentsection {
    font-size: 18px !important;
  } */

  .aboutbannercontent {
    font-size: 30px !important;
  }

  .countertext {
    font-size: 19px;
  }

  .contactcontent {
    font-size: 30px !important;
  }


  .autocontent {
    font-size: 20px !important;
  }

  .we-offer-area .item {
    background: #ffffff none repeat scroll 0 0 !important;
    border: medium none !important;
    padding: 29px 32px 31px !important;
    height: 242px !important;
  }

  .business {
    font-size: 29px;
    color: white;
    font-weight: 800;
  }

  .enderSection-left-wrapper .subheadingSection {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7 !important;
    text-align: justify !important;
    color: #0C1724;
    padding-left: 39px;
    padding-right: 65px;
    font-size: 15px;
  }

  .enderSection-left-wrapper .subheadingSection {
    font-style: normal;
    font-weight: 400;
    line-height: 1.7 !important;
    text-align: justify !important;
    color: #0C1724;
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 97px !important;
  }


}
@media only screen and (max-width: 768px) {
  .banner-line-hight {
    line-height: 1.5;
    font-size: 16px;
    color: white;
    font-weight: 500;
  }
  .hometext {
    /* margin-top: 200px !important; */
    position: absolute;
    top: 50%;
    transform: translateY(-46%);
    left: 6%;
}
}
@media only screen and (max-width: 280px) {
  .brandicon {
    width: 174px !important;
   
}
}
@media only screen and (max-width: 320px) {
.hometext {
  /* margin-top: 200px !important; */
  position: absolute;
  top: 50%;
  transform: translateY(-133%);
  left: 1%;
  
}
}
@media only screen and (max-width: 375px) {
  .hometext {
    /* margin-top: 200px !important; */
    position: absolute;
    top: 50%;
    transform: translateY(-82%);
    left: 1%;
    
  }
  .brandicon {
    width: 275px !important;
    height: 65px !important;
}
  }




  @media only screen and (max-width: 390px) {
  .brandicon {
    width: 250px !important;
    height: 69px;
}
}




@media only screen and (max-width: 425px) {
  .banner-line-hight {
    line-height: 1.5;
    font-size: 11px;
    color: white;
    font-weight: 500;
  }

 
  .business {
    font-size: 24px;
  }
}

@media only screen and (max-width: 425px){
.hometext {
    /* margin-top: 200px !important; */
    position: absolute;
    top: 50%;
    transform: translateY(-46%);
    left: 1%;
}
}

@media screen and (max-width: 320px) {

  .softwarecontents {

    font-size: 15px;

  }

  .servicecontents {
    font-size: 15px;
  }

  #counter .item .count {

    font-size: 33px;
  }

  .menu {
    padding: 0px;
    padding-left: 43px;
    padding-right: 22px;
  }

  .count {
    font-size: 15px;
  }

  .countertext {
    font-size: 17px;
    text-align: center;
  }

  .businesscontent {
    font-size: 40px;
    font-weight: 700;
  }

  .aboutcontentsection {
    font-size: 10px;
  }

  .testimonialcontent {
    font-size: 54px !important;
  }

  .brandicon {
    width: 184px !important;
    height: 57px !important;
  }

  #counter .item {
    background: #fff;
    width: 181px;
    padding: 31px 26px;
    margin: 59px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 0px 38px -8px #000000;
    transition: all 1s;
    border-radius: 50%;
  }



  .aboutbannercontent {
    font-size: 19px;
    top: 53%;
  }

  .contactcontent {
    font-size: 19px;
  }

  .autocontent {
    font-size: 12px;
  }


  .getintouch {
    font-size: 34px !important;
  }

  section {
    padding: 1px 0;

  }

  .form-wrap {


    padding: 33px 21px 62px 25px;


  }

}


@media screen and (max-width: 320px) {
  #counter {
    text-align: center;
    display: block;
    background: linear-gradient(125deg, rgba(134, 36, 171, 1) 13%, rgba(231, 2, 12, 1) 100%);
    padding: 40px 0px;
    width: 100%;

  }

}

/* Contact */

.indexsection {
  /* background-image: linear-gradient( 112.7deg,  rgba(253,185,83,1) 11.4%, rgba(255,138,0,1) 70.2% ); */
  background-color: #04003a;
}

.indextext {
  font-size: 24px;
  color: #000000;
  font-weight: 800;
}

.contactcontainer {
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.subform {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.formone {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgb(134, 6, 6) 0%, rgb(160, 27, 27) 90%);
  position: relative;
}


form {
  padding: 0.1rem 2.2rem;
  position: relative;
}

.contacttitle {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}

.forminput-container {
  position: relative;
  margin: 1rem 0;
}

.inputtext {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: white !important;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 1px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.inputtext {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.forminput-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.3s;
  color: #fafafa;
}

.forminput-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.sendbtn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  line-height: 1;
  border-radius: 25px;
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,131,8,1) 0%, rgba(245,63,63,1) 90% ); */
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  outline: none;
}

.sendbtn:hover {
  background-color: transparent;
  color: #fff;
}

.contact-information {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-information .title {
  color: #a31703;
}

.contacttextcontent {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  font-size: 0.95rem;
  align-items: center;
}

.icon {
  font-size: 30px;
  color: #1a70bc;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.socialmedia-icons {
  display: flex;
  margin-top: 0.5rem;
}

.socialmedia-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #a31703;
  text-align: center;
  color: #fff;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.4s;
}

.socialmedia-icons a:hover {
  transform: scale(1.09);
}



.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border: 80px solid #1a70bc;
  border-radius: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.forminput-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.forminput-container span::before,
.forminput-container span::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  background-color: #1a70bc;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
}

.forminput-container span::before {
  left: 50%;
}

.forminput-container span::after {
  right: 50%;
}

.forminput-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.forminput-container.focus span::before,
.forminput-container.focus span::after {
  width: 50%;
  opacity: 1;
}

@media (max-width: 850px) {
  .subform {
    grid-template-columns: 1fr;
  }

  .contact-information::before {
    bottom: initial;
    top: -72px;
    right: 65px;
    transform: scale(0.95);
  }

  .formone::before {
    top: -12px;
    left: initial;
    right: 70px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .contacttextcontenttext {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .contactcontainer {
    padding: 1.5rem;
  }

  .contact-information::before {
    display: none;
  }

  .big-circle {
    display: none;
  }

  form .contact-information {
    padding: 1.7rem 1.6rem;
  }

  .contacttextcontenttext,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .socialmedia-iconsa {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    font-size: 25px;
  }

  .inputtext {
    padding: 0.45rem 1.2rem;
  }

  .sendbtn {
    padding: 0.45rem 1.2rem;
  }
}

input::placeholder,
textarea::placeholder {
  color: white;
}



.blog {
  background-image: url('../img//blogbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;

}

.blogmaincontent {
  font-size: 40px;
  font-weight: 700;

}

.blogelement {
  font-weight: 500;
  font-size: 28px;
}

.blogbackground {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(237, 3, 32, 0.87) 20.8%, rgba(242, 121, 1, 0.84) 74.4%);
}

.blogservice {
  font-size: 45px;
  color: white;
  font-weight: 500;

}

.blogtextcontent {
  font-size: 17px;
  color: white;
}

.blogtextcontent:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  position: absolute;
  right: 0;
  top: 7px;
  padding: 4px 7px;
  line-height: 1;
  background: #fff;
  border-radius: 15px;
  color: #000;
  font-size: 14px;
}

.vertical {
  height: 85%;
  position: absolute;
  border: dashed;
  color: white;
  border-left: 0.5px;
}


.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
}

.ariaelementtext {
  font-size: 25px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.ariaelementtextbackground {
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
}

.blogariaelement {
  text-align: justify;
  font-size: 18px;
  font-weight: 200;
  color: #ffffff;
}

hr.style1 {
  border-top: 2px solid #ffffff;
}

.arrowicon {
  color: white;

}

.iconelement {
  float: right;
  margin-right: 281px;
}

hr.style2 {
  border-top: 4px solid #ffffff;
  width: 100px;
}

@media only screen and (max-width: 2560px) {
  .vertical {
    height: 32%;
    position: absolute;
    border: dashed;
    color: white;
    border-left: 0.5px;
  }
}


@media only screen and (max-width: 1440px) {
  .vertical {
    height: 38%;
    position: absolute;
    border: dashed;
    color: white;
    border-left: 0.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .iconelement {
    float: right;
    margin-right: 141px;
  }

  .enderSection-right-wrapper .circleGradientSection .gradient-element {
    background: linear-gradient(270deg, #f99d1c 9.65%, #f99c1c 10.1%, #f4781f 23.36%, #f16221 34.25%, #f05a22 41.18%, #ee1d62 69.98%, #ed0080 82.38%);
    border-radius: 75% !important;
    height: 352px !important;
    transform: translateY(87px) matrix(1, 0, 0, 1, -58, -107) !important;
    width: 367px !important;
  }
}

@media only screen and (max-width: 768px) {
  .iconelement {
    float: right;
    margin-right: 43px;
  }
}

@media only screen and (max-width: 320px) {
  .iconelement {
    float: right;
    margin-right: 11px;
  }
}


.courseheading {
  color: white;
  font-size: 37px;
  font-weight: 600;
}


.deliverymodemain>.container>.deliverystatsmain>.deliverystatsbox {
  display: inline-block;
  width: 260px;
  height: auto;
  padding: 10px;
  box-shadow: 4px 4px 10px #dae1eb;
  border-radius: 4px;
  margin-right: 35px;
  background: #ffffff;
}

.cardariacontent {

  font-size: 39px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  top: 45%;


}

/* service */

.abtcontent {
  font-size: 18px;
  color: white;

}

.service-grid {
  background-image: linear-gradient(89.7deg, rgba(0, 0, 0, 1) -10.7%, rgb(16, 6, 79) 88.8%);
}

.service-title h4 {
  position: relative;
  display: inline-block;
  color: white;
}

.service-icon {
  position: relative;
}

.service-title p {
  padding: 0 190px;
  margin-bottom: 10px;
}

.about-info-text h2 {
  margin-bottom: 23px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: .9;
}

.about-info-text p {
  padding: 0 100px;
  font-size: 18px;
  margin-bottom: 26px;
  position: relative;
}

.about-content p {
  margin-bottom: 0;
}

.about-info-text a {
  margin-bottom: 20px;
  position: relative;
}

.service-icon i {
  font-size: 40px;
  color: #e8bc00;
  margin-bottom: 20px;
  display: inline-block;
}

.service-wrap {

  padding: 50px 30px;
  position: relative;
  color: white;
}

.service-wrap p {
  position: relative;
  color: white;
}

.service-wrap:hover {
  /* background-image: url(https://i.ibb.co/mykzQks/services1.jpg); */
  background-size: cover;
  background-position: center center;
  background-attachment: local;
  transition: all .5s;
}

.service-wrap:hover:before {
  background: rgba(0, 0, 0, 0.2901960784313726);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  content: "";
  top: 0;
  opacity: 1;
}

.brandicon {
  width: 306px;
  height: 73px;
}






.service-wrap:hover h4,
.service-wrap:hover p {
  color: #fff;
}

.service-wrap h4 {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 17px;
  font-size: 18px;
  text-transform: capitalize;
  position: relative;
}

.service-wrap a {
  font-size: 14px;
  font-weight: 600;
  position: relative;

}

/* home */

.enderSection {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content min-content;

  border-style: solid;
  border-width: 4px;
  border-image-source: linear-gradient(to right, #f99d1c 9%, #f99c1c 9%, #f4781f 22%, #f16221 32%, #f05a22 38%, #ee1d62 65%, #ed0080 76%);
  border-image-slice: 1;
  background-color: rgba(255, 255, 255, .5);

  margin-top: 80px;
  margin-bottom: 60px;
}

.enderSection-left-wrapper {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
}

.enderSection-left-wrapper .headingSection {
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 110%;

  color: #0C1724;

  margin-top: 29px;
  padding-left: 34px;
  padding-right: 34px;
}

.enderSection-left-wrapper .subheadingSection {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7 !important;
  text-align: justify !important;


  color: #0C1724;

  margin-top: 10px;
  padding-left: 30px;
  padding-right: 62px;
}

.enderSection-left-wrapper .buttonsSection {
  margin-top: 21px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.enderSection-left-wrapper .buttonsSection .leftCTAWrapper {}

.enderSection-left-wrapper .buttonsSection .rightCTAWrapper {}

#enderContactSales,
#enderFreeTrial {
  pointer-events: initial;
}

.enderSection-right-wrapper {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 3;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  margin-top: 40px;
}

.enderSection-right-wrapper .circleGradientSection {
  display: none;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.enderSection-right-wrapper .circleGradientSection .gradient-element {
  width: 367px !important;
  height: 352px !important;
  background: linear-gradient(270deg, #F99D1C 9.65%, #F99C1C 10.1%, #F4781F 23.36%, #F16221 34.25%, #F05A22 41.18%, #EE1D62 69.98%, #ED0080 82.38%);
  transform: translateY(70px) matrix(-1, 0, 0, 1, -108, -89) !important;
  border-radius: 50% !important;
}

.enderSection-right-wrapper .computerSection {
  position: relative;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  justify-self: end;
  align-self: end;

  z-index: 0;
}

.enderSection-right-wrapper .computerSection .background-computer {
  position: relative;

  display: block;
  height: 225px;
  width: auto;
  transform: translateX(20px);
}

.enderSection-right-wrapper .smilingDudeSection {
  position: relative;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  justify-self: start;
  align-self: end;

  z-index: 1;
}

.enderSection-right-wrapper .smilingDudeSection .laughingDude {
  position: relative;

  display: block;
  height: 235px;
  width: auto;
}

@media (min-width: 787px) {
  .enderSection {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;

    grid-template-rows: min-content;
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .enderSection-left-wrapper {
    display: flex;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;

    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .enderSection-left-wrapper .headingSection {
    font-size: 40px;
    margin-top: 0;
    font-size: 30px;

  }

  .enderSection-left-wrapper .subheadingSection {
    margin-top: 22px;
    font-size: 18px;
  }

  .enderSection-left-wrapper .buttonsSection {
    margin-top: 41px;
    padding-left: 34px;
    padding-right: 34px;
    gap: 22px;
  }

  .enderSection-left-wrapper .buttonsSection .leftCTAWrapper {}

  .enderSection-left-wrapper .buttonsSection .rightCTAWrapper {}

  .enderSection-right-wrapper {
    display: grid;
    grid-column-start: 6;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;

    margin-top: 0;
  }

  .enderSection-right-wrapper .circleGradientSection {
    display: grid;
    overflow: hidden;
    align-items: end;
    justify-items: end;
  }

  .enderSection-right-wrapper .circleGradientSection .gradient-element {
    margin-top: -50px;
  }

  .enderSection-right-wrapper .computerSection {
    justify-self: unset;
    align-self: unset;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
    justify-items: end;
  }

  .enderSection-right-wrapper .computerSection .background-computer {
    height: 336px;

    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display: grid;
    transform: translateX(50px);
  }

  .enderSection-right-wrapper .smilingDudeSection {
    justify-self: unset;
    align-self: unset;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
    justify-items: start;
  }

  .enderSection-right-wrapper .smilingDudeSection .laughingDude {
    height: 326px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display: grid;
    transform: translateX(80px);
  }
}

/* container fixes to comply with bootstrap widths */
@media (min-width: 576px) {
  .enderSection {
    max-width: 735px;
  }
}

@media (min-width: 768px) {
  .enderSection {
    max-width: 767px;
  }
}

@media (min-width: 992px) {
  .enderSection {
    max-width: 930px;
  }
  @media (max-width: 1024px) {
    .enderSection-right-wrapper .circleGradientSection .gradient-element {
      width: 350px !important;
      height: 350px !important;
      background: linear-gradient(270deg, #F99D1C 9.65%, #F99C1C 10.1%, #F4781F 23.36%, #F16221 34.25%, #F05A22 41.18%, #EE1D62 69.98%, #ED0080 82.38%);
      transform: translateY(87px) matrix(-1, 0, 0, 1, -58, -107) !important;
      border-radius: 50% !important;
    }
  }
}

@media (min-width: 1200px) {
  .enderSection {
    max-width: 1110px;
  }
}

.aem-Grid {
  display: block;
  width: 100%;
}

.aem-Grid::before {
  display: table;
  content: " ";
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%;
}

.aem-GridColumn.aem-GridColumn--default--12 {
  padding-left: 0px;
  padding-right: 0px;
}

.aem-GridColumn {
  padding: 0 15px;
}

.aem-GridColumn {
  box-sizing: border-box;

}

.rte-container {
  width: 100%;
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%;
}

.aem-GridColumn.aem-GridColumn--default--12 {
  padding-left: 0px;
  padding-right: 0px;
}

.rte-container {
  background-color: white;
  width: 100%;
}



/* footer */



h1,
h2,
h3,
h4,
h5,
h6 {}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #000;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 25px 0;
  /* min-height: 100vh;*/
}

.footer {
  background: linear-gradient(105deg, #030303, #1d1d1d);
  padding-top: 80px;
  padding-bottom: 8px;
}

/*END FOOTER SOCIAL DESIGN*/
.single_footer {}

@media only screen and (max-width:768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}

.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

.single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 20px;
}

.single_footer p {
  color: #fff;
}

.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single_footer ul li {}

.single_footer ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
}

.single_footer ul li a:hover {
  color: #a31703;
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
  color: #fff;
}

.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}

.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}

.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

@media only screen and (max-width:768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.subscribe__btn i {
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #ff3666;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}

.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.social_profile ul li {
  float: left;
}

.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width:480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.social_profile ul li a:hover {
  background: #a31703;
  border: 1px solid #a31703;
  color: #fff;
  border: 0px;
}

/*END SOCIAL PROFILE CSS*/
.copyright {
  /* padding-top: 12px; */
  color: #fff;
  font-size: 15px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.4); */
  text-align: center;
}

.copyright a {
  color: #ffffff;
  transition: all 0.2s ease 0s;
}

.copyright a:hover {
  color: #ff3666;
}

.footertext {
  color: white;
  text-align: justify;
}

.support {
  color: white !important;
}

.socialmedia-icons {
  color: #000;
}

.footertsupport {
  font-weight: 700;
  font-size: 19px;
  color: black;

}
.active {
  color: #a31703 !important;
  font-weight: bold; /* For example, make it bold */
}
.footer_sec {
  background-color: #181818;
  color: #ccc;
}
.footer_align h6 {
  color: #fff;
  font-weight: 600;
}
.list-unstyled {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.footer_align ul li {
  font-size: 14px;
  padding: 8px 0;
}
.footer_align ul li a {
  color: #ccc;
}
hr {
  border: 0;
  border-top: 1px solid;
  border-top: var(--bs-border-width) solid;
  color: inherit;
  margin: 1rem 0;
  opacity: .25;
}
.bytagtes, .copyright {
  font-size: 12px;
}
.bytagtes span a {
  color: #a00;
}
.foot_icon a svg{
  font-size: 25px;
}